<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import TabFees from './components/brokerage_tab_fees.vue'
import TabBasicInfo from './components/brokerage_tab_basic_info.vue'
import TabSupplier from './components/brokerage_tab_supplier_list.vue'
import TabPMSupplier from './components/brokerage_tab_pm_supplier_list.vue'
import TabAccountingConfig from './components/brokerage_tab_accounting.vue'
import TabTaxConfig from './components/brokerage_tab_tax.vue'
import TabAccountinGlMapConfig from './components/brokerage_tab_accounting_gl.vue'
import TabExpenseConfig from './components/brokerage_tab_expense.vue'
import TabDealTypeConfig from './components/brokerage_tab_deal_type.vue'
//import TabHoliday from './components/brokerage_tab_statutory_holiday.vue'

/**
 * Project-overview component
 */
export default {
  page: {
    title: "Brokerage Overview",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Brokerage Overview",
      items: [
        {
          text: "Setting",
        },
        {
          text: "",
          active: true,
        },
      ],
    };
  },
  components: {
    Layout,
    PageHeader,
    TabFees,
    TabBasicInfo,
    TabSupplier,
    TabAccountingConfig,
    TabAccountinGlMapConfig,
    TabExpenseConfig,
    TabPMSupplier,
    TabTaxConfig,
    TabDealTypeConfig,
   // TabHoliday
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card mb-0">
          <div class="card-body pb-4">


          </div>
          <!-- end card body -->
        </div>
        <!-- en card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
    <div class="row">
      <b-tabs
          class="mt-n5 pt-2"
          content-class="card card-body mt-3"
          nav-class="nav-tabs-custom"
          nav-wrapper-class="nav-tabs-custom"
        >
          <b-tab title="Basic Info" active>
            <TabBasicInfo />
          </b-tab>
          <b-tab title="Fee Configuration">
            <TabFees />
          </b-tab>

          <b-tab title="Expense Configuration">
            <TabExpenseConfig />
          </b-tab>

          

          <b-tab title="Supplier">
            <TabSupplier />
          </b-tab>

          <b-tab title="PM Supplier">
            <TabPMSupplier />
          </b-tab>

          
          <!-- <b-tab title="Statory Holiday">
            <TabHoliday />
          </b-tab> -->

          <b-tab title="Accounting">
            <TabAccountingConfig />
          </b-tab>
          <b-tab title="Accounting Tax">
            <TabTaxConfig />
          </b-tab>

          

          <b-tab title="Accounting GL Map">
            <TabAccountinGlMapConfig />
          </b-tab>
          
          <b-tab title="Deal Type">
            <TabDealTypeConfig />
          </b-tab>
          
          
          
        
        </b-tabs>
        <!-- Nav tabs -->
    </div>
  </Layout>
</template>
