<script>




import appConfig from "@/app.config";



import { getBrokerageApi } from '@/api/brokerage';

/**
 * Create-project component
 */
export default {
  page: {
    title: "Setup Brokerage",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Brokerage",
      items: [
        {
          text: "Brokerage",
        },
        {
          text: "Configuration",
          active: true,
        },
      ],
      date: null,
     
      gl_ar_commission : '',
      gl_commission_agents : '',
      gl_tax_collection: '',
      gl_tax_payable : '',
      gl_commission_income : '',
      gl_commission_payable : '',
      gl_ap_supplier : '',
      gl_ar_agent_gen_expense : '',
      gl_ar_rebgv_discount : '',
      gl_ap_referral_other_brokerage : '',
      gl_ap_comm_other_brokerage : '',
      


    };
  },
  components: {
   
  },
  mounted() {
    getBrokerageApi().configApi.gl_config().then((res)=> {
      if (res.errCode == 0) {
        this.gl_ar_commission           = res.data['AR_COMMISSION_FROM_DEAL']
        this.gl_commission_agents       = res.data['COMMISSION_AGENTS']
        this.gl_tax_collection          = res.data['GLOBAL_GST_COLLECTION_GL_CODE']
        this.gl_tax_payable             = res.data['GLOBAL_GST_PAID_GL_CODE']
        this.gl_commission_income       = res.data['COMMISSION_INCOME']
        this.gl_commission_payable      = res.data['COMMISSION_PAYABLE']
        this.gl_ap_supplier             = res.data['AP_SUPPLIER']
        this.gl_ar_agent_gen_expense    = res.data['AGENT_GEN_EXPENSE']
        this.gl_ar_rebgv_discount       = res.data['REBGV_DISCOUNT']
        this.gl_ap_referral_other_brokerage = res.data['AP_REFERRAL_OTHER_BROKERAGE']
        this.gl_ap_comm_other_brokerage    = res.data['COMMISSION_TO_OTHER']



      }
    })
    
  },

  methods: {
    
    saveGLConfig() {
      let fields = [
        {key :'AR_COMMISSION_FROM_DEAL', 'account' : this.gl_ar_commission},
        {key :'COMMISSION_AGENTS' , 'account' : this.gl_commission_agents},
        {key :'COMMISSION_INCOME' , 'account' : this.gl_commission_income},
        {key :'GLOBAL_GST_COLLECTION_GL_CODE' , 'account' : this.gl_tax_collection},
        {key :'GLOBAL_GST_PAID_GL_CODE' , 'account' : this.gl_tax_payable},
        {key :'COMMISSION_PAYABLE' , 'account' : this.gl_commission_payable},
        {key :'AP_SUPPLIER' , 'account' : this.gl_ap_supplier},
        {key : 'AGENT_GEN_EXPENSE', 'account' : this.gl_ar_agent_gen_expense},
        {key : 'REBGV_DISCOUNT', 'account' : this.gl_ar_rebgv_discount},
        {key : 'AP_REFERRAL_OTHER_BROKERAGE', 'account' : this.gl_ap_referral_other_brokerage},
        {key : 'COMMISSION_TO_OTHER', 'account' : this.gl_ap_comm_other_brokerage}
      ]

      getBrokerageApi().configApi.gl_config_udpate({gls: fields}).then((res) => {
        if (res.errCode == 0) {
          this.$alertify.message("Save Success");
        } else {
          this.$alertify.error("Save Failed " + res.errCode);
        }
      })
    },


  },
};
</script>

<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div>

            <div>
              <div class="text-center mb-4">
                <h5></h5>
              </div>
              <form>
                <div class="row">
                  <div class="mb-3">
                    <label for="gst_number" class="form-label">GST Collected GL#</label>
                    <input id="gst_number" v-model="gl_tax_collection" type="text" class="form-control"
                      placeholder="GST Collection" />
                  </div>
                  <div class="mb-3">
                    <label for="gst_number" class="form-label">GST Paid GL#</label>
                    <input  v-model="gl_tax_payable" type="text" class="form-control"
                      placeholder="GST Paid" />
                  </div>

                  <div class="mb-3">
                    <label for="gst_number" class="form-label">Commission Payable GL#</label>
                    <input  v-model="gl_commission_payable" type="text" class="form-control"
                      placeholder="Commission Pabable" />
                  </div>

                  <div class="mb-3">
                    <label for="gst_number" class="form-label">Commission Income GL#</label>
                    <input  v-model="gl_commission_income" type="text" class="form-control" maxlength="15"
                      placeholder="Commission Income" />
                  </div>

                  <div class="mb-3">
                    <label for="gst_number" class="form-label">Commission Agents GL#</label>
                    <input  v-model="gl_commission_agents" type="text" class="form-control" maxlength="30"
                      placeholder="COmmission Agents" />
                  </div>
                  <div class="mb-3">
                    <label for="gst_number" class="form-label">AR Commission GL#</label>
                    <input v-model="gl_ar_commission" type="text" class="form-control" maxlength="30"
                      placeholder="AR Commission" />
                  </div>


                   <div class="mb-3">
                    <label for="gst_number" class="form-label">AP Supplier GL#</label>
                    <input v-model="gl_ap_supplier" type="text" class="form-control" maxlength="30"
                      placeholder="AP Supplier GL" />
                  </div>


                  <div class="mb-3">
                    <label for="gst_number" class="form-label">AR Agent Gen Expense GL#</label>
                    <input v-model="gl_ar_agent_gen_expense" type="text" class="form-control" maxlength="30"
                      placeholder="AR Agent Gen Expense GL" />
                  </div>
                  <div class="mb-3">
                    <label for="gst_number" class="form-label">REBGV Discount GL#</label>
                    <input v-model="gl_ar_rebgv_discount" type="text" class="form-control" maxlength="30"
                      placeholder="AR Agent Gen Expense GL" />
                  </div>

                  <div class="mb-3">
                    <label for="gst_number" class="form-label">AP REFERRAL OTHER BROKERAGE</label>
                    <input v-model="gl_ap_referral_other_brokerage" type="text" class="form-control" maxlength="30"
                      placeholder="AP REFERAL OTHER BROKERAGE" />
                  </div>

                  <div class="mb-3">
                    <label for="gst_number" class="form-label">AP REFERRAL OTHER COMMISSION</label>
                    <input v-model="gl_ap_comm_other_brokerage" type="text" class="form-control" maxlength="30"
                      placeholder="AP REFERAL OTHER Commission" />
                  </div>

                  
                  

                </div>
              </form>
            </div>
            <!-- wizard-tab -->



            <div class="d-flex align-items-start gap-3 mt-4">
              <button type="button" class="btn btn-primary w-sm ms-auto" @click="saveGLConfig">
                Save
              </button>
            </div>
          </div>
        </div>
        <!-- end card body -->
      </div>
      <!-- end card -->
    </div>
    <!-- end col -->
  </div>
  <!-- end row -->
</template>
