

<script>


import FeeSetupDialog from './fee_setup_dialog.vue'
import { getBrokerageApi } from '@/api/brokerage';
export default {


    props: ['brokerage'],
    components: {
        FeeSetupDialog
    },

    validations: {

    },



    data() {
        return {
            fee_list: [],
            totalRows: 0,
            currentPage: 1,
            perPage: 100,
            expense: {
                name: "",
                code: "",
                category: "EXPENSE",
                accounting_gl: "",
                accounting_gst_gl: "23001",
                amount: "",
                fixed_type: "FIXED",
                tax_flag : 1,
            }
        }
    },

    methods: {
        pageChange(p) {
            this.currentPage = p
            this.queryList()
        },

        queryList() {
            let data = {
                page : {
                    page : this.currentPage,
                    pageSize : 200,
                }
            }
            getBrokerageApi().configApi.expense_fee_list(data).then(res => {
                if (res.errCode == 0) {
                    this.fee_list = res.data
                    this.totalRows = res.data.length
                }
            })
        },

        expense_setup_save(new_fee) {
            
            new_fee.category ='EXPENSE'
            if (new_fee.fixed_type == 'FIXED') {
                new_fee.fixed_fee = new_fee.amount
            } else {
                new_fee.fixed_rate = new_fee.amount
            }


            if (new_fee.id) {

                getBrokerageApi().configApi.fee_update(new_fee).then((res) => {
                    if (res.errCode == 0) {
                        this.queryList()
                        this.$alertify.message("Expense  Setup Success");
                        this.$bvModal.hide('modal-expense-setup-dialog')
                    } else {
                        this.$alertify.error("Failed to Setup new Fee " + res.errCode);
                    }

                })

            } else {

                getBrokerageApi().configApi.setup_new_fee(new_fee).then((res) => {
                    if (res.errCode == 0) {
                        this.queryList()
                        this.$alertify.message("Expense  Setup Success");
                        this.$bvModal.hide('modal-expense-setup-dialog')
                    } else {
                        this.$alertify.error("Failed to Setup new Fee " + res.errCode);
                    }

                })

            }

        },

        update_fee(fee) {
            this.expense = fee

            if (fee.fixed_type == 'FIXED') {
                this.expense.amount = fee.fixed_fee
            } else {
                this.expense.amount = fee.fixed_rate
            }

            this.$bvModal.show('modal-expense-setup-dialog')
        }
    },

    created() {

    },

    mounted() {
        this.queryList()
    }
}

</script>
<template>
    <div class="row">
        <div class="d-flex align-items-start">
            <div class="flex-grow-1">
                <h5 class="card-title"></h5>
            </div>
            <div class="flex-shrink-0">
                <b-dropdown right variant="white" toggle-class="btn-link text-dark p-0" menu-class="dropdown-menu-end">
                    <template v-slot:button-content>
                        <i class="uil uil-ellipsis-h"></i>
                    </template>
                    <b-dropdown-item v-b-modal.modal-expense-setup-dialog>Setup New Expense</b-dropdown-item>
                </b-dropdown>

                <b-modal centerd :id="'modal-expense-setup-dialog'" title="Expense Setup" size="xl" hide-footer>
                    <FeeSetupDialog :fee="expense" :expense_flag="true" @cancel="$bvModal.hide('modal-expense-setup-dialog')"
                        @confirm="expense_setup_save" />
                </b-modal>
            </div>
        </div>

        <div data-simplebar >
            <div class="table-responsive">
                <b-table :items="fee_list" :fields="['Code', 'Name','Account', 'AccountGst','Taxable', 'Action']" responsive="sm" :per-page="perPage" class="table-check">

                    <template #cell(Code)="data">
                        {{ data.item.code }}
                    </template>
                    <template #cell(Name)="data">
                        {{ data.item.name }}
                    </template>
                  
                    <template #cell(Fee)="data">
                        {{ data.item.fixed_type == 'FIXED' ? data.item.fixed_fee : (data.item.fixed_rate *
                            100).toFixed(2) + '%' }}
                    </template>
                    <template #cell(Taxable)="data">
                        {{ data.item.tax_flag == 1 ? 'Yes' : 'N/A' }}
                    </template>
                    <template #cell(Account)="data">
                        {{ data.item.accounting_gl }}
                    </template>
                    <template #cell(AccountGst)="data">
                        {{ data.item.accounting_gst_gl }}
                    </template>

                    <template #cell(Action)="data">
                        <b-dropdown variant="light" right size="sm">
                            <template v-slot:button-content>
                                <i class="uil uil-ellipsis-h"></i>
                            </template>
                            <b-dropdown-item @click="update_fee(data.item)">Modify</b-dropdown-item>
                        </b-dropdown>
                    </template>
                </b-table>

                <div class="row g-0 text-center text-sm-start">
                    <!-- end col -->
                    <div class="col-sm-12">
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-end">
                                    <!-- pagination -->
                                    <b-pagination class="pagination-rounded" v-model="currentPage" :total-rows="totalRows"
                                        :per-page="perPage" @change="pageChange"></b-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end col -->
                </div>
                <!-- end row -->
            </div>
        </div>
    </div>
</template>