

<script>


import FeeAutoComplete from '@/components/fee-auto-complete-input.vue'
import FeeSetupDialog from './fee_setup_dialog.vue'
import { getBrokerageApi } from '@/api/brokerage';
export default {


    props: ['brokerage'],
    components: {
        FeeSetupDialog,
        FeeAutoComplete
    },

    validations: {

    },



    data() {
        return {
            searched_fee_code : '',
            deal_type_list : [],
            fee_list: [],
            totalRows: 0,
            currentPage: 1,
            perPage: 50,
            pageOptions: [10, 25, 50, 100],
            fields: [
                {
                    key: "FeeCode",
                    sortable: true,
                },
                {
                    key: "FeeName",
                    sortable: true,
                },
                {
                    key: "Category",
                    sortable: true,
                },
                {
                    key: "Fee",
                    sortable: true,
                },
                {
                    key: "TaxFlag",
                    label : 'Taxable',
                    sortable: true,
                },
                {
                    key: "AccountGL",
                    label : 'GL Number',
                    sortable: true,
                },
                {
                    key: "AccountGstGL",
                    label : 'GL GST Number',
                    sortable: true,
                },
                {
                    key : 'Action'
                }

            ],
            fee: {
                name: "",
                code: "",
                category: "ANNUALLY",
                accounting_gl: "",
                accounting_gst_gl: "",
                amount: "",
                fixed_type: "FIXED",
                tax_flag  : 1,
                deal_type : 'EXTRA',
            }
        }
    },

    methods: {
        pageChange(p) {
            this.currentPage = p
            this.queryList()
        },

        export_fee_conf() {
            getBrokerageApi().configApi.export_fee_conf({export_type : 'XLSX'}).then((res) => {
                if (res.errCode == 0) {
                    window.open(res.url, '_blank')
                }
            })

            
        },

        queryList() {
            let data = { page: { page: this.currentPage, page_size: this.perPage } }
            if (this.searched_fee_code) {
                data.code = this.searched_fee_code
            }
            getBrokerageApi().configApi.fee_list(data).then((res) => {
                if (res.errCode == 0) {
                    this.fee_list = res.data
                    this.totalRows = res.page.total
                }
            })
        },

        query_deal_type () {
            getBrokerageApi().configApi.deal_type_list({}).then((res) => {
                if (res.errCode == 0) {
                    this.deal_type_list.push(...res.data)
                }
            })
        },

        fee_setup_save(new_fee) {
            
           
            if (new_fee.fixed_type == 'FIXED') {
                new_fee.fixed_fee = new_fee.amount
            } else {
                new_fee.fixed_rate = new_fee.amount
            }


            if (new_fee.id) {

                getBrokerageApi().configApi.fee_update(new_fee).then((res) => {
                    if (res.errCode == 0) {
                        this.queryList()
                        this.$alertify.message("Fee  Setup Success");
                        this.$bvModal.hide('modal-fee-setup-dialog')
                    } else {
                        this.$alertify.error("Failed to Setup new Fee " + res.errCode);
                    }

                })

            } else {

                getBrokerageApi().configApi.setup_new_fee(new_fee).then((res) => {
                    if (res.errCode == 0) {
                        this.queryList()
                        this.$alertify.message("Fee  Setup Success");
                        this.$bvModal.hide('modal-fee-setup-dialog')
                    } else {
                        this.$alertify.error("Failed to Setup new Fee " + res.errCode);
                    }

                })

            }

        },

        update_fee(fee) {
            this.fee = fee

            if (fee.fixed_type == 'FIXED') {
                fee.amount = fee.fixed_fee
            } else {
                fee.amount = fee.fixed_rate * 100
            }

            this.$bvModal.show('modal-fee-setup-dialog')
        },

        showNewFeeDialog() {
            this.fee = {
                name: "",
                category: "MONTHLY",
                accounting_gl: "",
                accounting_gst_gl: "23000",
                amount: "",
                fixed_type: "FIXED",
                tax_flag  : 1,
                deal_type : 'EXTRA',
            }
            this.$bvModal.show('modal-fee-setup-dialog')
        },

        onFeeSelect(evt) {
            this.currentPage = 1
            this.searched_fee_code = evt.value
            this.queryList()
        },

        onFeeCleared() {
            this.searched_fee_code = ''
            this.currentPage = 1
            this.queryList()
        }
    },

    created() {

    },

    mounted() {
        this.query_deal_type()
        this.queryList()
    }
}

</script>
<template>
    <div class="row">
        <div class="d-flex align-items-start">
            <div class="col-md-1 mt-2">
                <label>Search Fee</label>
            </div>
            <div class="col-md-3">
                 <FeeAutoComplete :init_data="{placeholder: 'Search Fee'}" @onSelected="onFeeSelect" @onCleared="onFeeCleared"/>
            </div>
            <div class="flex-grow-1">
                <h5 class="card-title"></h5>
            </div>
            <div class="flex-shrink-0">
                <b-dropdown right variant="white" toggle-class="btn-link text-dark p-0" menu-class="dropdown-menu-end">
                    <template v-slot:button-content>
                        <i class="uil uil-ellipsis-h"></i>
                    </template>
                    <b-dropdown-item  @click="showNewFeeDialog">Setup New Fee</b-dropdown-item>
                    <b-dropdown-item  @click="export_fee_conf">Export Fee</b-dropdown-item>

                    
                </b-dropdown>

                <b-modal centerd :id="'modal-fee-setup-dialog'" title="Fee Setup" size="xl" hide-footer>
                    <FeeSetupDialog :fee="fee" :deal_type_list="deal_type_list" @cancel="$bvModal.hide('modal-fee-setup-dialog')"
                        @confirm="fee_setup_save" />
                </b-modal>
            </div>
        </div>

        <div data-simplebar >
            <div class="table-responsive">
                <b-table :items="fee_list" :fields="fields" responsive="sm" :per-page="perPage" class="table-check">

                    <template #cell(FeeCode)="data">
                        {{ data.item.code }}
                    </template>
                    <template #cell(FeeName)="data">
                        {{ data.item.name }}
                    </template>
                    <template #cell(Category)="data">
                        {{ data.item.category }}
                    </template>
                    <template #cell(Fee)="data">
                        {{ data.item.fixed_type == 'FIXED' ? data.item.fixed_fee : (data.item.fixed_rate *
                            100).toFixed(2) + '%' }}
                    </template>
                    <template #cell(TaxFlag)="data">
                        {{ data.item.tax_flag == 1 ? 'GST' : 'N/A' }}
                    </template>
                    <template #cell(AccountGL)="data">
                        {{ data.item.accounting_gl }}
                    </template>
                    <template #cell(AccountGstGL)="data">
                        {{ data.item.accounting_gst_gl }}
                    </template>

                    <template #cell(Action)="data">
                        <b-dropdown variant="light" right size="sm">
                            <template v-slot:button-content>
                                <i class="uil uil-ellipsis-h"></i>
                            </template>
                            <b-dropdown-item @click="update_fee(data.item)">Modify</b-dropdown-item>
                        </b-dropdown>
                    </template>
                </b-table>

                <div class="row g-0 text-center text-sm-start">
                    <!-- end col -->
                    <div class="col-sm-12">
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-end">
                                    <!-- pagination -->
                                    <b-pagination class="pagination-rounded" v-model="currentPage" :total-rows="totalRows"
                                        :per-page="perPage" @change="pageChange"></b-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end col -->
                </div>
                <!-- end row -->
            </div>
        </div>
    </div>
</template>