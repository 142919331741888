<script>


/**
 * Fee Setup  component
 */



import {
    required,
} from "vuelidate/lib/validators";
import Choices from "choices.js";


import {getAccountingGLApi} from '@/api/accounting/query'
import { getBrokerageApi } from '@/api/brokerage';


export default {

    emits: ['confirm', 'cancel'],

    props: ['fee', 'expense_flag', 'deal_type_list'],

    components: {

    },

    validations: {
        fee: {
            name: { required },
            amount: { required },
            code  : {required},
        },
    },



    data() {

        return {
            submitted: false,
            gl_choice: {},
            gl_items: [],
            gl_gst_items : [],
            supplier_list : [],
            supplier_choice: null,

        }

    },
    methods: {
        formSubmit() {
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$error == false) {
                this.fee.is_supplier_fee = this.fee.is_supplier_fee? 1 : 0
                this.$emit('confirm', this.fee)
            }
        },


        onDealTypeCheck(dt, evt) {
            if (evt.target.checked == true) {
                let d = this.fee.deal_type_list.find(e => e == dt.code)
                if (!d) {
                    this.fee.deal_type_list.push(dt.code)
                }
            } else {
                this.fee.deal_type_list = this.fee.deal_type_list.filter(e => e != dt.code)
            }
        },

        onSupplierChanged() {
            
        },

        onBindSupplierChanged() {
            if (this.fee.bind_to_supplier) {
                this.supplier_choice.enable()
            }  else {
                this.fee.supplier_id = ''
                this.supplier_choice.removeActiveItems()
                this.supplier_choice.disable()
            }
        }
    },

    created() {

        getAccountingGLApi().gl_list({ page: { page: 1, page_size: 500 } }).then((res) => {
            this.gl_items = []
            res.data.map((g) => {
                let label = g.account + ' ' + g.description + '(' + g.sign + ')'
                this.gl_items.push({
                    label: label,
                    value: g.account,
                    selected: g.account == Number(this.fee.accounting_gl) ? true : false,
                    g: g,
                })
            })

            this.gl_choice.setChoices(this.gl_items)
        })

        if (!this.fee.deal_type_list) {
            this.fee.deal_type_list = []
        }


        getBrokerageApi().configApi.brokerage_supplier_list({page : {page : 1, page_size : 50}}).then((res) => {
            if (res.errCode == 0 ) {
                this.supplier_list = []
                res.data.map((s) => {
                    this.supplier_list.push({
                        label : s.name,
                        value : s.id,
                        c : s
                    })
                })
                this.supplier_choice.setChoices(this.supplier_list)
            }
        })
        

       
       
    },

    mounted() {

       
        this.gl_choice = new Choices('#gl_choice', {
            placeholderValue: "This is a placeholder set in the config",
            searchPlaceholderValue: "search by name",
        })
        new Choices('#gl_gst_choice', {
            placeholderValue: "",
            searchPlaceholderValue: "",
            choices : [
                {label : '23001', value : '23001', selected : this.fee.accounting_gst_gl == '23001'},
                {label : '23000', value: '23000', selected : this.fee.accounting_gst_gl == '23000'},
               
            ]
        })


        
        new Choices('#fixed_type', {
            placeholderValue: "This is a placeholder set in the config",
            searchPlaceholderValue: "search by name",
        })

        let c = new Choices('#fee_category', {
            searchEnabled: false
        })

        let choice_items = []
        if (this.expense_flag) {
            choice_items.push({label : 'Expense', value : 'EXPENSE', selected: true})
            c.setChoices(choice_items)
        } else {
            
            choice_items.push({label : 'Annual', value : 'ANNUALLY', selected: this.fee.category == 'ANNUALLY' ? true : false})
            choice_items.push({label : 'Monthly', value : 'MONTHLY', selected: this.fee.category == 'MONTHLY' ? true : false})
            choice_items.push({label : 'Other', value : 'OTHER', selected: this.fee.category == 'OTHER' ? true : false})
            choice_items.push({label : 'Listing', value : 'LISTING', selected: this.fee.category == 'OTHER' ? true : false})
            choice_items.push({label : 'Deal', value : 'DEAL', selected: this.fee.category == 'DEAL' ? true : false})
            c.setChoices(choice_items)
        }

        this.supplier_choice = new Choices('#choices-supplier-input', {
            placeholderValue: "This is a placeholder set in the config",
            searchPlaceholderValue: "search by name",
        })

    }

};
</script>

<template>
    <div class="card">
        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">

                <div class="row">
                    <div class="col-md-3">
                        <div class="mb-2"> 
                            <label for="validationCustom01">Category</label>
                            <select class="form-control" data-trigger v-model="fee.category" name="fee_category"   id="fee_category">
        
                            </select>
                        </div>
                    </div>
                    <div class="col-md-1">
                        <div class="mb-2"> 
                            <label>Code</label>
                            <input  v-model="fee.code" type="text" class="form-control" maxlength="5" :class="{
                                    'is-invalid':  $v.fee.code.$error,
                                }" :disabled="fee.id"/>
                            <div v-if=" $v.fee.code.$error" class="invalid-feedback">
                                <span v-if="!$v.fee.code.required">This value is required.</span>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="mb-3">
                            <label for="validationCustom01">Name</label>
                            <input id="validationCustom01" v-model="fee.name" type="text" class="form-control"
                                placeholder="" value="" :class="{
                                    'is-invalid': submitted && $v.fee.name.$error,
                                }" />
                            <div v-if="submitted && $v.fee.name.$error" class="invalid-feedback">
                                <span v-if="!$v.fee.name.required">This value is required.</span>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-2">
                        <div class="mb-3">
                            <label for="validationCustom01">Type</label>
                            <select class="form-control" data-trigger v-model="fee.fixed_type" name="fixed_type" id="fixed_type">
                                <option value="FIXED">Fixed</option>
                                <option value="RATE">Rate</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-md-2">
                        <div class="mb-3">
                            <label for="validationCustom01">{{fee.fixed_type =='RATE' ? 'Rate(%)' :'Amount'}}</label>
                            <input id="validationCustom01" v-model="fee.amount" type="text" class="form-control"
                                placeholder="$"  :class="{
                                    'is-invalid': submitted && $v.fee.amount.$error,
                                }" />
                            <div v-if="submitted && $v.fee.amount.$error" class="invalid-feedback">
                                <span v-if="!$v.fee.amount.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- end row-->

                <div class="row">


                    <div class="col-md-5">
                        <div class="mb-3">
                            <div class="mb-3">
                                <label for="validationCustom01">GL </label>
                                <select class="form-control" data-trigger v-model="fee.accounting_gl" name="gl_choice"
                                    id="gl_choice">
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-1">
                        <div class="mb-3">
                            <div class="mb-3">
                                <label for="validationCustom01">Tax</label>
                               
                            </div>
                            <input type="checkbox" v-model="fee.tax_flag" id="tax_include"  checked />
                                <label
                                    for="tax_include"
                                    data-on-label="Yes"
                                    data-off-label="No"
                                    data-on-value="1"
                                    data-off-value="0"
                                    class="mb-0"
                                ></label>
                        </div>
                    </div>


                    <div class="col-md-2" :class="fee.tax_flag == '1'?'block':'d-none'">
                        <div class="mb-3">
                            <div class="mb-3">
                                <label for="validationCustom01">GL GST</label>
                                <select class="form-control" data-trigger v-model="fee.accounting_gst_gl" name="gl_gst_choice" id="gl_gst_choice" >
                                  
                                </select>
                            </div>
                        </div>
                    </div>


                    <div class="col-md-2" >
                        <div class="mb-3">
                            <div class="mb-3">
                                <label for="validationCustom01">Recoverable</label>
                               
                            </div>
                            <input type="checkbox" v-model="fee.is_supplier_fee" id="update_agent_include"  :checked="fee.is_supplier_fee" />
                                <label
                                    for="update_agent_include"
                                    data-on-label="Yes"
                                    data-off-label="No"
                                    data-on-value="1"
                                    data-off-value="0"
                                    class="mb-0"
                                ></label>
                        </div>
                    </div>


                    <div class="col-md-2" :class="!expense_flag?'block':'d-none'">
                        <div class="mb-3">
                            <div class="mb-3">
                                <label for="validationCustom01">Upadate Agent</label>
                               
                            </div>
                            <input type="checkbox" v-model="fee.is_update_agents" id="update_agent_include"  :checked="fee.bind_to_supplier" />
                                <label
                                    for="update_agent_include"
                                    data-on-label="Yes"
                                    data-off-label="No"
                                    data-on-value="1"
                                    data-off-value="0"
                                    class="mb-0"
                                ></label>
                        </div>
                    </div>
                    
                   
                </div>
                
                <!-- end row -->

                <div class="row d-none">
                    <div class="col-md-4" >
                        <div class="mb-3">
                            <div class="mb-3">
                                <label for="validationCustom01">Bind to Supplier   <input type="checkbox" v-model="fee.bind_to_supplier" id="bind_to_supplier"  @change="onBindSupplierChanged" /></label>
                            
                                <select class="form-control" v-model="fee.supplier_id" name="choices-supplier-input"
                                    id="choices-supplier-input" @change="onSupplierChanged" >
                                </select>
                            </div>
                            
                            
                        </div>
                    </div>

                   
                </div>
                <!-- end row-->

                <div class="row"  v-if="fee.category == 'DEAL'">
                    <div class="col-md-6">
                        <div class="mt-3">
                            <label >Deal Type Bind:</label>
                        </div>
                    </div>
                </div>
                <div class="table-responsive" v-if="fee.category == 'DEAL'">
                    <b-table :items="deal_type_list" :fields="[{key : 'C', label : ''},'Code', 'Name']" responsive="sm" :per-page="20" class="table-check">

                        <template #cell(C)="data">
                            <input type="checkbox" class="form-check" :checked="data.checked" @change="onDealTypeCheck(data.item, $event)" />
                        </template>
                        <template #cell(Code)="data">
                            {{ data.item.code }}
                        </template>
                        <template #cell(Name)="data">
                            {{ data.item.fields.name }}
                        </template>
                    </b-table>

                
                </div>


                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                            <b-button variant="primary" type="submit">Save</b-button>
                        </div>
                    </div>
                    <div class="col-md-4">

                    </div>

                </div>
                <!-- end row-->


            </form>
        </div>
    </div>
    <!-- end card -->
</template>