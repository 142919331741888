<script>




import appConfig from "@/app.config";



import { getBrokerageApi } from '@/api/brokerage';

/**
 * Create-project component
 */
export default {
  page: {
    title: "Setup Brokerage",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Brokerage",
      items: [
        {
          text: "Brokerage",
        },
        {
          text: "Configuration",
          active: true,
        },
      ],
      date: null,
     
      brokerage_gst_number : '',
      brokerage_eft_short_name : '',
      brokerage_eft_long_name :'',

    };
  },
  components: {
   
  },
  mounted() {
    getBrokerageApi().configApi.brokerage_config().then((res)=> {
      if (res.errCode == 0) {
        this.brokerage_gst_number  = res.data['brokerage_gst_number']
        this.brokerage_eft_short_name  = res.data['short_name']
        this.brokerage_eft_long_name  = res.data['long_name']


      }
    })
    
  },

  methods: {
    
    saveBasicInfo() {
      let fields = {
        'brokerage_gst_number': this.brokerage_gst_number,
        'short_name' : this.brokerage_eft_short_name,
        'long_name' : this.brokerage_eft_long_name,

      }

      getBrokerageApi().configApi.save_brokerage_configuration(fields).then((res) => {
        if (res.errCode == 0) {
          this.$alertify.message("Save Success");
        } else {
          this.$alertify.error("Save Failed " + res.errCode);
        }
      })
    },

    fileUploaded(ev, resp) {
        console.log("uploaded" , ev.upload, ev, resp)
        if (resp[0].errCode == 200) {
            this.brokerage_logo = resp[0].response.url
        }
    },

  },
};
</script>

<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div>

            <div>
              <div class="text-center mb-4">
                <h5></h5>
              </div>
              <form>
                <div>
                  <div class="mb-3">
                    <label for="gst_number" class="form-label">GST Number#</label>
                    <input id="gst_number" v-model="brokerage_gst_number" type="text" class="form-control"
                      placeholder="Enter GST Number" />
                  </div>

                  <div class="mb-3">
                    <label for="gst_number" class="form-label">EFT Brokerage Short Name</label>
                    <input id="gst_number" v-model="brokerage_eft_short_name" type="text" class="form-control" maxlength="15"
                      placeholder="Enter EFT Brokerage Short Name" />
                  </div>

                  <div class="mb-3">
                    <label for="gst_number" class="form-label">EFT Brokerage Long Name</label>
                    <input id="gst_number" v-model="brokerage_eft_long_name" type="text" class="form-control" maxlength="30"
                      placeholder="Enter EFT Brokerage Long Name" />
                  </div>
                </div>
              </form>
            </div>
            <!-- wizard-tab -->



            <div class="d-flex align-items-start gap-3 mt-4">
              <button type="button" class="btn btn-primary w-sm ms-auto" @click="saveBasicInfo">
                Save
              </button>
            </div>
          </div>
        </div>
        <!-- end card body -->
      </div>
      <!-- end card -->
    </div>
    <!-- end col -->
  </div>
  <!-- end row -->
</template>
