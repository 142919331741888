<script>


import {
    required,
} from "vuelidate/lib/validators";


export default {

    emits: ['confirm', 'cancel'],

    props: ['deal_type'],
    components: {

    },

    validations: {
        deal_type: {
            code: { required },
            fields: {name: { required }},
        },
    },



    data() {

        return {
            
        }

    },
    methods: {
        formSubmit() {
            
            this.$v.$touch();
            if (this.$v.$error == false) {
                this.$emit('confirm', this.deal_type)
            }
        },



        
    },

    created() {
      
        if (!this.deal_type.fields) {
            this.deal_type.fields = {
                name           : "",
                description    : "",
                agt_com_gl     : '',
                c_to_bkg_gl    : '',
                revenue_gl     : ''
            }
        }

       
    },

    mounted() {
     
      
    }

};
</script>

<template>
    <div class="card">
        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
                <div class="row">
                    <div class="col-md-2">
                        <div class="mb-3">
                            <label for="validationCustom01">Code</label>
                            <input id="validationCustom01" v-model="deal_type.code" type="text" class="form-control"
                                placeholder="" value="" :class="{
                                    'is-invalid': $v.deal_type.code.$error,
                                }" />
                            <div v-if=" $v.deal_type.code.$error" class="invalid-feedback">
                                <span v-if="!$v.deal_type.code.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="mb-2"> 
                            <label for="supplier_phone">Name</label>
                            <input id="validationCustom01" v-model="deal_type.fields.name" type="text" class="form-control"
                                placeholder="" value="" :class="{
                                    'is-invalid': $v.deal_type.fields.name.$error,
                                }" />
                            <div v-if=" $v.deal_type.fields.name.$error" class="invalid-feedback">
                                <span v-if="!$v.deal_type.fields.name.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                   
                   
                </div>
                <!-- end row-->


                <div class="row">
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label for="validationCustom01">Revenue Acct GL#</label>
                            <input id="validationCustom01" v-model="deal_type.fields.revenue_gl" type="text" class="form-control"
                                placeholder="Commission Income"   />
                        </div>
                    </div>
                </div>
                <!-- end row-->

                <div class="row">
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label for="validationCustom01">Commission Other Acct GL#</label>
                            <input id="validationCustom01" v-model="deal_type.fields.c_to_bkg_gl" type="text" class="form-control"
                                placeholder="Commission To Other Brokers" />
                        </div>
                    </div>
                </div>
                <!-- end row-->

                <div class="row">
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label for="validationCustom01">Agent Commission Acct GL#</label>
                            <input id="validationCustom01" v-model="deal_type.fields.agt_com_gl" type="text" class="form-control"
                                placeholder="Agents Commission"   />
                        </div>
                    </div>
                </div>
                <!-- end row-->


                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                            <b-button variant="primary" type="submit">Save</b-button>
                        </div>
                    </div>
                    <div class="col-md-4">

                    </div>

                </div>
                <!-- end row-->


            </form>
        </div>
    </div>
    <!-- end card -->
</template>