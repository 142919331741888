

<script>



import { getBrokerageApi } from '@/api/brokerage';

import DealTypeEditDialog from './deal_type_edit_dialog.vue'
export default {


    props: ['brokerage'],
    components: {
        DealTypeEditDialog
    },

    validations: {

    },



    data() {
        return {
            deal_type_list: [],
            totalRows: 0,
            currentPage: 1,
            perPage: 50,
            action : 'New',
            fields: [
               
                {
                    key: "Code",
                    sortable: true,
                },
                {
                    key: "Name",
                    sortable: true,
                },
               
                {
                    key: "Action",
                    sortable: true,
                },

            ],
            deal_type: {
                code : '',
                fields : {
                    name           : "",
                    description    : "",
                    agt_com_gl     : '',
                    c_to_bkg_gl    : '',
                    revenue_gl     : ''
                }
              
        
            }
        }
    },

    methods: {
       
        queryList() {
            getBrokerageApi().configApi.deal_type_list({}).then((res) => {
                if (res.errCode == 0) {
                    
                    this.deal_type_list = res.data
                }
            })
        },

        onDealTypeEdit(c) {
            c.action = this.action
            getBrokerageApi().configApi.update_deal_type(c).then((res) => {
                if (res.errCode == 0) {
                    this.queryList()
                    this.$alertify.message("Success");
                    this.$bvModal.hide('modal-deal-type-edit-dialog')
                  
                } else {
                    this.$alertify.error("Failed code: " + res.errCode);
                }

            })
        },

        showEditDealTypeDialog(deal_type) {
            if (deal_type) {
                this.deal_type = deal_type
                this.action    = 'Update'
            } else {
                this.action    = 'New'
            }
            this.$bvModal.show('modal-deal-type-edit-dialog')
        }
    },

    created() {

    },

    mounted() {
        this.queryList()
    }
}

</script>
<template>
    <div class="row">
        <div class="d-flex align-items-start">
            <div class="flex-grow-1">
                <h5 class="card-title">Deal Type List</h5>
            </div>
            <div class="flex-shrink-0">
                <b-dropdown right variant="white" toggle-class="btn-link text-dark p-0" menu-class="dropdown-menu-end">
                    <template v-slot:button-content>
                        <i class="uil uil-ellipsis-h"></i>
                    </template>
                    <b-dropdown-item @click="showEditDealTypeDialog(null)">Add New Deal Type</b-dropdown-item>
                </b-dropdown>

                <b-modal centerd :id="'modal-deal-type-edit-dialog'" title="supplier" size="lg" hide-footer>
                    <DealTypeEditDialog :deal_type="deal_type" @cancel="$bvModal.hide('modal-deal-typer-edit-dialog')"
                        @confirm="onDealTypeEdit" />
                </b-modal>
            </div>
        </div>

        <div data-simplebar >
            <div class="table-responsive">
                <b-table :items="deal_type_list" :fields="fields" responsive="sm" :per-page="perPage" class="table-check">

                   
                    <template #cell(Code)="data">
                        {{ data.item.code }}
                    </template>
                    <template #cell(Name)="data">
                        {{ data.item.fields.name }}
                    </template>
                   
                    <template #cell(Action)="data">
                        <b-dropdown variant="light" right size="sm">
                            <template v-slot:button-content>
                                <i class="uil uil-ellipsis-h"></i>
                            </template>
                            <b-dropdown-item @click="showEditDealTypeDialog(data.item)">Edit</b-dropdown-item>
                        </b-dropdown>

                    </template>
                </b-table>

               
            </div>
        </div>
    </div>
</template>