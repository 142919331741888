<script>




import appConfig from "@/app.config";



import { getBrokerageApi } from '@/api/brokerage';

/**
 * Create-project component
 */
export default {
  page: {
    title: "Setup Brokerage",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Brokerage Tax",
      items: [
        {
          text: "Brokerage Tax",
        },
        {
          text: "Configuration",
          active: true,
        },
      ],
      date: null,
      gst_number      : '',
      name            : '',
      address         : '',
      city            :'',
      province        : '',
      country_code    : '',
      postal_code     : '',
      contact_person  : '',
      contact_area_code : '',
      contact_number     : '',
      contact_email      : '',
      transmission_code  : '',
      nr_code            : '',

    };
  },
  components: {
   
  },
  mounted() {
    getBrokerageApi().configApi.brokerage_config().then((res)=> {
      if (res.errCode == 0) {
        let tax_data              = res.data['tax']
        this.name                 = tax_data['name']
        this.address              = tax_data['address']
        this.city                 = tax_data['city']
        this.province             = tax_data['province']
        this.country_code         = tax_data['country_code']
        this.postal_code           = tax_data['postal_code']
        this.gst_number           = tax_data['gst_number']
        this.transmission_code    = tax_data['transmission_code']

        this.contact_person       = tax_data['contact_person']
        this.contact_area_code    = tax_data['contact_area_code']
        this.contact_number       = tax_data['contact_number']
        this.contact_email        = tax_data['contact_email']
        this.nr_code              = tax_data['nr_code']

      }
    })
    
  },

  methods: {
    
    saveTaxInfo() {
      let fields = {
        tax : {
          'name'               : this.name,
          'address'            : this.address,
          'city'               : this.city,

          'province'           : this.province,
          'country_code'       : this.country_code,
          'postal_code'        : this.postal_code,

          'gst_number'         : this.gst_number,
          'transmission_code'  : this.transmission_code,

          'contact_person'     : this.contact_person,
          'contact_number'     : this.contact_number,
          'contact_email'      : this.contact_email,
          'nr_code'            : this.nr_code,

        }
       

      }

      getBrokerageApi().configApi.save_brokerage_configuration(fields).then((res) => {
        if (res.errCode == 0) {
          this.$alertify.message("Save Success");
        } else {
          this.$alertify.error("Save Failed " + res.errCode);
        }
      })
    },


  },
};
</script>

<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div>

            <div>
              <div class="text-center mb-4">
                <h5></h5>
              </div>
              <form>
                <div>
                  <div class="mb-3">
                    <label for="gst_number" class="form-label">GST Number#</label>
                    <input id="gst_number" v-model="gst_number" type="text" class="form-control"
                      placeholder="Enter GST Number" />
                  </div>


                  <div class="mb-3">
                    <label for="gst_number" class="form-label">Transmission Code</label>
                    <input id="gst_number" v-model="transmission_code" type="text" class="form-control" maxlength="10"
                      placeholder="Enter EFT Brokerage Tax Transmission Code" />
                  </div>

                  <div class="mb-3">
                    <label for="gst_number" class="form-label">NR Code</label>
                    <input id="gst_number" v-model="nr_code" type="text" class="form-control" maxlength="7"
                      placeholder="Enter EFT Brokerage NR Code" />
                  </div>

                  <div class="mb-3">
                    <label for="gst_number" class="form-label">Brokerage Name</label>
                    <input id="gst_number" v-model="name" type="text" class="form-control" maxlength="15"
                      placeholder="Enter EFT Brokerage Short Name" />
                  </div>

                  <div class="mb-3">
                    <label for="gst_number" class="form-label">Address</label>
                    <input id="gst_number" v-model="address" type="text" class="form-control" maxlength="30"
                      placeholder="Address" />
                  </div>

                  <div class="mb-3">
                    <label for="gst_number" class="form-label">City</label>
                    <input id="gst_number" v-model="city" type="text" class="form-control" maxlength="30"
                      placeholder="City" />
                  </div>

                  <div class="mb-3">
                    <label for="gst_number" class="form-label">Province</label>
                    <input id="gst_number" v-model="province" type="text" class="form-control" maxlength="30"
                      placeholder="Province" />
                  </div>

                  <div class="mb-3">
                    <label for="gst_number" class="form-label">Postal Code</label>
                    <input id="gst_number" v-model="postal_code" type="text" class="form-control" maxlength="7"
                      placeholder="Postal Code" />
                  </div>

                  <div class="mb-3">
                    <label for="gst_number" class="form-label">Country Code</label>
                    <input id="gst_number" v-model="country_code" type="text" class="form-control" maxlength="3"
                      placeholder="Country Code(CAN)" />
                  </div>

                  <div class="mb-3">
                    <label for="gst_number" class="form-label">Contact Person</label>
                    <input id="gst_number" v-model="contact_person" type="text" class="form-control" maxlength="50"
                      placeholder="Contact Name" />
                  </div>

                  <div class="mb-3 row">
                    <label for="gst_number" class="form-label">Phone</label>
                    <input id="gst_number" v-model="contact_number" type="text" class="form-control" maxlength="10"
                      placeholder="Enter Contact Number" />
                  </div>

                  <div class="mb-3">
                    <label for="gst_number" class="form-label">Email</label>
                    <input id="gst_number" v-model="contact_email" type="text" class="form-control" maxlength="50"
                      placeholder="Contact  Email" />
                  </div>

                </div>
              </form>
            </div>
            <!-- wizard-tab -->



            <div class="d-flex align-items-start gap-3 mt-4">
              <button type="button" class="btn btn-primary w-sm ms-auto" @click="saveTaxInfo">
                Save
              </button>
            </div>
          </div>
        </div>
        <!-- end card body -->
      </div>
      <!-- end card -->
    </div>
    <!-- end col -->
  </div>
  <!-- end row -->
</template>
