

<script>


import SupplierAutoComplete from '@/components/supplier-auto-complete-input.vue'
import { getBrokerageApi } from '@/api/brokerage';

import SupplierEditDialog from './supplier_edit_dialog.vue'
export default {


    props: ['brokerage'],
    components: {
        SupplierEditDialog,
        SupplierAutoComplete
    },

    validations: {

    },



    data() {
        return {
            supplier_list: [],
            totalRows: 0,
            currentPage: 1,
            perPage: 50,
            pageOptions: [10, 25, 50, 100],
            fields: [
                {
                    key: "Id",
                    sortable: true,
                },
                {
                    key: "Code",
                    sortable: true,
                },
                {
                    key: "Name",
                    sortable: true,
                },
                {
                    key: "Type",
                    sortable: true,
                },
                
                {
                    key: "Address",
                    sortable: true,
                },
                {
                    key: "Phone",
                    sortable: true,
                },
                {
                    key: "Action",
                    sortable: true,
                },

            ],
            supplier: {
                name: "",
                address: "",
                s_type : 1 ,
                
                 bank_info : {
                    institution : '',
                    transit     : '',
                    account     : ''
                 }
        
            },
            searched_supplier_code : '',
        }
    },

    methods: {
        pageChange(p) {
            this.currentPage = p
            this.queryList()
        },

        queryList() {
            let data = { page: { page: this.currentPage, page_size: this.perPage }, query_type:['1', '2'] }
            if (this.searched_supplier_code) {
                data.query_supplier_code = this.searched_supplier_code
            }
            getBrokerageApi().configApi.brokerage_supplier_list(data).then((res) => {
                if (res.errCode == 0) {
                    this.supplier_list = res.data
                    this.totalRows = res.page.total
                }
            })
        },

        supplier_save(c) {
           
            getBrokerageApi().configApi.supplier_update(c).then((res) => {
                if (res.errCode == 0) {
                    this.queryList()
                    this.$alertify.message("Success");
                    this.$bvModal.hide('modal-pm-supplier-edit-dialog')
                  
                } else {
                    this.$alertify.error("Failed code: " + res.errCode);
                }

            })
        },

        showEditSupplierDialog(supplier) {
            if (supplier) {
                this.supplier = supplier
            } else {
                this.supplier = {
                    name: "",
                    address: "",
                    s_type : 1,
                    bank_info : {
                        institution : '',
                        transit     : '',
                        account     : ''
                    }
                }
            }
            this.$bvModal.show('modal-pm-supplier-edit-dialog')
        },

        onSupplierSelect(evt) {
            this.currentPage = 1
            this.searched_supplier_code = evt.supplier_code
            this.queryList()
        },

        onSupplierCleared() {
            this.searched_supplier_code =''
            this.currentPage = 1
            this.queryList()
        }
    },

    created() {

    },

    mounted() {
        this.queryList()
    }
}

</script>
<template>
    <div class="row">
        <div class="d-flex align-items-start">

            <div class="flex-grow-1">
                <h5 class="card-title">supplier List</h5>
            </div>
            <div class="col-md-3">
                 <SupplierAutoComplete :init_data="{placeholder: 'Search Supplier'}" :query_type="'PM'" @onSelected="onSupplierSelect" @onCleared="onSupplierCleared"/>
            </div>
            <div class="flex-shrink-0 col-md-6">
            </div>

            <div class="flex-shrink-0">
                <b-dropdown right variant="white" toggle-class="btn-link text-dark p-0" menu-class="dropdown-menu-end">
                    <template v-slot:button-content>
                        <i class="uil uil-ellipsis-h"></i>
                    </template>
                    <b-dropdown-item @click="showEditSupplierDialog(null)">Add New PM Supplier</b-dropdown-item>
                </b-dropdown>

                <b-modal centerd :id="'modal-pm-supplier-edit-dialog'" title="supplier" size="lg" hide-footer>
                    <SupplierEditDialog :supplier="supplier" @cancel="$bvModal.hide('modal-pm-supplier-edit-dialog')"
                        @confirm="supplier_save" />
                </b-modal>
            </div>
        </div>

        <div data-simplebar >
            <div class="table-responsive">
                <b-table :items="supplier_list" :fields="fields" responsive="sm" :per-page="perPage" class="table-check">

                    <template #cell(Id)="data">
                        {{ data.item.id }}
                    </template>
                    <template #cell(Code)="data">
                        {{ data.item.supplier_code }}
                    </template>
                    <template #cell(Name)="data">
                        {{ data.item.name }}
                    </template>
                    <template #cell(Type)="data">
                        {{ data.item.payment_type}}
                    </template>
                    <template #cell(Address)="data">
                        {{ data.item.address }}
                    </template>
                    <template #cell(Phone)="data">
                        {{ data.item.phone}}
                    </template>
                    <template #cell(Action)="data">
                        <b-dropdown variant="light" right size="sm">
                            <template v-slot:button-content>
                                <i class="uil uil-ellipsis-h"></i>
                            </template>
                            <b-dropdown-item @click="showEditSupplierDialog(data.item)">Edit</b-dropdown-item>
                        </b-dropdown>

                    </template>
                </b-table>

                <div class="row g-0 text-center text-sm-start">
                    <!-- end col -->
                    <div class="col-sm-12">
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-end">
                                    <!-- pagination -->
                                    <b-pagination class="pagination-rounded" v-model="currentPage" :total-rows="totalRows"
                                        :per-page="perPage" @change="pageChange"></b-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end col -->
                </div>
                <!-- end row -->
            </div>
        </div>
    </div>
</template>