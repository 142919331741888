<script>


/**
 * Fee Setup  component
 */



import {
    required,
} from "vuelidate/lib/validators";
import Choices from "choices.js";

import { getBrokerageApi } from "@/api/brokerage";


export default {

    emits: ['confirm', 'cancel'],

    props: ['supplier'],
    components: {

    },

    validations() {

        if (this.supplier.payment_type =='EFT') {
            return {
                supplier: {
                    name: { required },
                    address: { required },
                    supplier_code: { required },
                    bank_info: {
                        institution : {required},
                        transit :{required},
                        account :{required}
                    }
                },
            }
        } else {
            return {
                supplier: {
                    name: { required },
                    address: { required },
                    supplier_code: { required },
                    bank_info: {
                        institution : {},
                        transit :{},
                        account :{}
                    }
                },
            }
            
        }
       
        
    },



    data() {

        return {
            
            expense_items : [],
            recoverable_fee_choice : {}
        }

    },
    methods: {
        formSubmit() {
            
            this.$v.$touch();
            if (this.$v.$error == false) {
                this.supplier.bind_agent_supplier = this.supplier.bind_agent_supplier? 1 : 0
                this.$emit('confirm', this.supplier)
            }
        },



        
    },

    created() {

       
        getBrokerageApi().configApi.expense_fee_list().then(res => {
            if(res.errCode == 0) {
                res.data.map(e => {
                    let label = e.name + ' ' + e.accounting_gl 
                    this.expense_items.push({
                        label: label,
                        value: e.code,
                        selected: e.code == this.supplier.expense_fee_code ? true : false,
                        e: e,
                    })

                    this.expense_items.push(e)
                })
                this.expense_choice.setChoices(this.expense_items)
            }
        })
        

     
    },

    mounted() {
       
      
        this.expense_choice = new Choices('#expense_choice', {
            placeholderValue: "This is a placeholder set in the config",
            searchPlaceholderValue: "search by name",
        })

        this.recoverable_fee_choice = new Choices('#recoverable_fee_choice', {
            placeholderValue: "This is a placeholder set in the config",
            searchPlaceholderValue: "search by name",
        })

        

        new Choices("#payment_type", {
            removeItemButton: false,
            choices : [
                {label : 'Cheque', value : 'CHEQUE', selected: this.supplier && this.supplier.payment_type == 'CHEQUE'? true : false},
                {label : 'EFT', value : 'EFT', selected: this.supplier && this.supplier.payment_type == 'EFT'? true : false},
            ]
        });

        console.log(this.supplier.recoverable_fee_code)
        let fee_code_list = []
        getBrokerageApi().configApi.fee_list({ page: { page: 1, page_size: 200 } }).then((res) => {
            if (res.errCode == 0) {
                res.data.map( e=> {
                    fee_code_list.push({
                        label : e.name +'('+e.code+')',
                        value : e.code,
                        fee   :e,
                        selected : e.code == this.supplier.recoverable_fee_code ? true : false
                    })
                })
                this.recoverable_fee_choice.setChoices(fee_code_list)
                
            }
        })


      
    }

};
</script>

<template>
    <div class="card">
        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
                <div class="row">
                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="validationCustom01">Name</label>
                            <input id="validationCustom01" v-model="supplier.name" type="text" class="form-control"
                                placeholder="" value="" :class="{
                                    'is-invalid': $v.supplier.name.$error,
                                }" />
                            <div v-if=" $v.supplier.name.$error" class="invalid-feedback">
                                <span v-if="!$v.supplier.name.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="mb-2"> 
                            <label for="supplier_phone">Phone</label>
                            <input id="supplier_phone" v-model="supplier.phone" type="text" class="form-control"
                                placeholder="Phone" value="" />
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="supplier_email">Email</label>
                            <input id="supplier_email" v-model="supplier.email" type="text" class="form-control"
                                placeholder="email" value="" />
                        </div>
                    </div>
                   
                </div>
                <!-- end row-->

                <div class="row">
                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="supplier_email">Code</label>
                            <input id="supplier_email" v-model="supplier.supplier_code" type="text" class="form-control"
                                placeholder="code" value="" :class="{  'is-invalid':  $v.supplier.supplier_code.$error}"  />
                            <div v-if=" $v.supplier.supplier_code.$error" class="invalid-feedback">
                                <span v-if="!$v.supplier.supplier_code.required">This value is required.</span>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-8">
                        <div class="mb-3">
                            <label for="address">Address</label>
                            <input id="address" v-model="supplier.address" type="text" class="form-control"
                                placeholder="Address"  :class="{
                                    'is-invalid':  $v.supplier.address.$error,
                                }" />
                            <div v-if=" $v.supplier.address.$error" class="invalid-feedback">
                                <span v-if="!$v.supplier.address.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                    
                   
                </div>
                <!-- end row-->

                <div class="row">

                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="workexperience-companyname-input">Payment Type</label>
                            <select id="payment_type" v-model="supplier.payment_type">
                            
                            </select>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="mb-2">
                            <label for="workexperience-companyname-input">Recoverable Supplier</label>
                            <input type="checkbox" class="form-check  mt-2" v-model="supplier.bind_agent_supplier" id="recoverable_supplier"/>
                            <label
                                    for="recoverable_supplier"
                                    data-on-label="Yes"
                                    data-off-label="No"
                                    data-on-value="1"
                                    data-off-value="0"
                                    class="mb-0"
                                ></label>
                        </div>
                    </div>


                    <div class="col-md-4">
                        <div class="mb-2">
                            <label for="workexperience-companyname-input">Active</label>
                            <input type="checkbox" class="form-check  mt-2" v-model="supplier.is_active" />
                            <label
                                    for="recoverable_supplier"
                                    data-on-label="Yes"
                                    data-off-label="No"
                                    data-on-value="1"
                                    data-off-value="0"
                                    class="mb-0"
                                ></label>
                        </div>
                    </div>

                </div>
                <!-- end row-->

                    <div class="row mb-3" >
                        <div class="col-md-4">
                        <div class="mb-md-0">
                            <label for="contact-info-website-input" class="form-label">Financial Institution</label>
                            <input type="text" class="form-control" v-model="supplier.bank_info.institution" 
                                :class="{
                                    'is-invalid':  $v.supplier.bank_info.institution.$error,
                                }"
                            placeholder="Enter Bank  Institution Number"  />
                            <div v-if=" $v.supplier.bank_info.institution.$error" class="invalid-feedback">
                                <span v-if="!$v.supplier.bank_info.institution.required">This value is required.</span>
                            </div>
                        </div>
                        </div>
                        <div class="col-md-4">
                        <div class="mb-md-0">
                            <label for="contact-info-location-input" class="form-label">Financial Transit</label>
                            <input type="text" class="form-control" v-model="supplier.bank_info.transit" 
                            :class="{
                                    'is-invalid':  $v.supplier.bank_info.transit.$error,
                                }"
                            placeholder="Enter Bank Transit Number" />
                            <div v-if=" $v.supplier.bank_info.transit.$error" class="invalid-feedback">
                                <span v-if="!$v.supplier.bank_info.transit.required">This value is required.</span>
                            </div>
                        </div>
                        </div>
                        <div class="col-md-4">
                        <div class="mb-md-0">
                            <label for="contact-info-location-input" class="form-label">Financial Account</label>
                            <input type="text" class="form-control" v-model="supplier.bank_info.account" 
                            :class="{
                                    'is-invalid':  $v.supplier.bank_info.account.$error,
                                }"
                            placeholder="Enter Bank Account"  />
                            <div v-if=" $v.supplier.bank_info.account.$error" class="invalid-feedback">
                                <span v-if="!$v.supplier.bank_info.account.required">This value is required.</span>
                            </div>
                        </div>
                        </div>
                    </div>



                <div class="row">
                    <div class="col-md-8">
                        <div class="mb-3">
                            <label for="address">Expense Type</label>

                            <select class="form-control" data-trigger v-model="supplier.expense_fee_code" name="expense_choice"
                                id="expense_choice" :disabled="supplier.s_type == 1?true : false">
                            </select>

                        </div>
                    </div>
                    
                   
                </div>
                <!-- end row-->



                <div class="row" :class="supplier.bind_agent_supplier?'block':'d-none'">
                    <div class="col-md-8">
                        <div class="mb-3">
                            <label for="address">Recoverable Fee</label>

                            <select class="form-control" data-trigger v-model="supplier.recoverable_fee_code" name="recoverable_fee_choice"
                                id="recoverable_fee_choice" >
                            </select>

                        </div>
                    </div>
                    
                   
                </div>
                <!-- end row-->


                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                            <b-button variant="primary" type="submit">Save</b-button>
                        </div>
                    </div>
                    <div class="col-md-4">

                    </div>

                </div>
                <!-- end row-->


            </form>
        </div>
    </div>
    <!-- end card -->
</template>