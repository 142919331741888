<script>




import appConfig from "@/app.config";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";




import { getBrokerageApi } from '@/api/brokerage';

/**
 * Create-project component
 */
export default {
  page: {
    title: "Setup Brokerage",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Brokerage",
      items: [
        {
          text: "Brokerage",
        },
        {
          text: "Configuration",
          active: true,
        },
      ],
      date: null,
      dropzoneOptions: {
        url: "/AwsFileUpload/s3_upload_multi",
        thumbnailWidth: 150,
        maxFilesize: 10,
      },
      brokerage_name: "",
      brokerage_address: "",
      brokerage_logo: "",
      brokerage_fax : "",
      brokerage_tel : '',
      brokerage_email : '',
      brokerage_gst_number : '',

    };
  },
  components: {
    vueDropzone: vue2Dropzone,
  },
  mounted() {
    getBrokerageApi().configApi.brokerage_config().then((res)=> {
      if (res.errCode == 0) {
        this.brokerage_name  = res.data['brokerage_name']
        this.brokerage_address  = res.data['brokerage_address']
        this.brokerage_logo  = res.data['brokerage_logo']
        this.brokerage_fax  = res.data['brokerage_fax']
        this.brokerage_tel  = res.data['brokerage_tel']
        this.brokerage_email  = res.data['brokerage_email']
        this.brokerage_gst_number = res.data['brokerage_gst_number']

      }
    })
    
  },

  methods: {
    
    saveBasicInfo() {
      let fields = {
        'brokerage_name': this.brokerage_name,
        'brokerage_address': this.brokerage_address,
        'brokerage_logo': this.brokerage_logo,
        'brokerage_email' : this.brokerage_email,
        'brokerage_tel' : this.brokerage_tel,
        'brokerage_fax' : this.brokerage_fax,
        'brokerage_gst_number' :this.brokerage_gst_number,
      }

      getBrokerageApi().configApi.save_brokerage_configuration(fields).then((res) => {
        if (res.errCode == 0) {
          this.$alertify.message("Save Success");
        } else {
          this.$alertify.error("Save Failed " + res.errCode);
        }
      })
    },

    fileUploaded(ev, resp) {
        console.log("uploaded" , ev.upload, ev, resp)
        if (resp[0].errCode == 200) {
            this.brokerage_logo = resp[0].response.url
        }
    },

  },
};
</script>

<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div>

            <div class="col-lg-4">
              <vue-dropzone id="dropzone" ref="myVueDropzone" :use-custom-slot="true" :options="dropzoneOptions"   @vdropzone-success="fileUploaded">
                <div class="dropzone-custom-content">
                  <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                  <h4>Drop phot here or click to upload Logo.</h4>
                </div>
              </vue-dropzone>
            </div>

            <div>
              <div class="text-center mb-4">
                <h5></h5>
              </div>
              <form>
                <div>
                  <div class="mb-3">
                    <label for="brokerageName" class="form-label">Brokerage Name</label>
                    <input id="brokerageName" v-model="brokerage_name" type="text" class="form-control"
                      placeholder="Enter Brokerage Name" />
                  </div>

                  <div class="mb-3">
                    <label for="BrokerageAddress" class="form-label">Brokerage Address</label>
                    <textarea class="form-control" v-model="brokerage_address" id="brokerageAddress" rows="3"
                      placeholder="Enter Address..."></textarea>
                  </div>

                  <div class="mb-3">
                    <label for="BrokerageAddress" class="form-label">Brokerage Fax</label>
                    <input class="form-control" v-model="brokerage_fax" 
                      placeholder="Enter Fax..." />
                  </div>

                  <div class="mb-3">
                    <label for="BrokerageAddress" class="form-label">Brokerage Tel</label>
                    <input class="form-control" v-model="brokerage_tel" 
                      placeholder="Enter Telephone..." />
                  </div>

                  <div class="mb-3">
                    <label for="BrokerageAddress" class="form-label">Brokerage Email</label>
                    <input class="form-control" v-model="brokerage_email" 
                      placeholder="Enter Email..." />
                  </div>
                  <div class="mb-3">
                    <label for="BrokerageAddress" class="form-label">Brokerage GST Number</label>
                    <input class="form-control" v-model="brokerage_gst_number" 
                      placeholder="Enter GST Number..." />
                  </div>

                  
                </div>
              </form>
            </div>
            <!-- wizard-tab -->



            <div class="d-flex align-items-start gap-3 mt-4">
              <button type="button" class="btn btn-primary w-sm ms-auto" @click="saveBasicInfo">
                Save
              </button>
            </div>
          </div>
        </div>
        <!-- end card body -->
      </div>
      <!-- end card -->
    </div>
    <!-- end col -->
  </div>
  <!-- end row -->
</template>
